export const SET_GAMEOPTIONS = '[DATA] SET GAME OPTIONS';
export const SET_APPVERSION = '[DATA] SET APPVERSION';
export const SET_SETTING = '[DATA] SET SETTING';
export const SAVE_SETTING = '[DATA] SAVE SETTING';
export const SET_TEAMS = '[DATA] SET TEAMS';
export const SET_SPORTS = '[DATA] SET SPORTS';
export const SET_PROFILE = '[DATA] SET PROFILE';
export const SET_CURLOCATION = '[DATA] SET CURLOCATION';
export const SET_SHOW_BAR = '[DATA] SET SHOW BAR';
export const SET_ENTERED_GAMES_SCREEN = '[DATA] SET ENTERED GAMES SCREEN';
export const SET_CARD_FILTER = '[DATA] SET CARD FILTER';
export const SET_ACTIVITY = '[DATA] SET ACTIVITY';
export const SET_WISHLISTS = '[DATA] SET WISHLISTS';
export const SET_CHEERS = '[DATA] SET CHEERS'
export const SET_MY_CARDS = '[DATA] SET MY CARDS'
export const SET_GO_TOP_OF_HOME = '[DATA] SET GO TOP OF HOME'
export const SET_SIDEBAR_COLLAPSED = '[DATA] SET SIDEBAR COLLAPSED'
export const SET_HOME_TAB_INDEX = '[DATA] SET HOME TAB INDEX'
export const SET_CHANGED_CARD = '[DATA] SET CHANGED CARD'
export const SET_CURRENT_CARD = '[DATA] SET CURRENT CARD'
export const SET_CHANGED_FANFEED = '[DATA] SET CHANGED FANFEED'
export const SET_CREATED_FANFEED = '[DATA] SET CREATED FANFEED'
export const SET_EXCHANGE_RATE = '[DATA] SET EXCHANGE RATE'
export const SET_CURRENT_WALLET_BALANCE = '[DATA] SET CURRENT WALLET BALANCE'
export const SET_FOLLOWING_USER_IDS = '[DATA] SET FOLLOWING USER IDS'
export const ADD_FOLLOWING_USER_ID = '[DATA] ADD FOLLOWING USER ID'
export const REMOVE_FOLLOWING_USER_ID = '[DATA] REMOVE FOLLOWING USER ID'
export const SET_EDIT_CARD_ACTION = '[DATA] EDIT CARD ACTION'
export const SET_BUY_CARD_ACTION = '[DATA] BUY CARD ACTION'
export const SET_ADVANCED_SEARCH_FILTER_ACTION = '[DATA] ADVANCED SEARCH FILTER ACTION'
export const SET_FANFEED_FILTERS = '[DATA] FANFEED FILTERS'
export const SET_FANFEED_FILTER_ACTION = '[DATA] FANFEED FILTER ACTION'

export const setGameOptions = (game_options) => {
  return (dispatch) => {
    dispatch({ type: SET_GAMEOPTIONS, payload: game_options });
  };
};

export const setAppVersion = (version) => {
  return (dispatch) => {
    dispatch({ type: SET_APPVERSION, payload: version });
  };
};

export const setShowBar = (show) => {
  return (dispatch) => {
    dispatch({ type: SET_SHOW_BAR, payload: show });
  }
}

export const setEnteredGamesScreen = (entered) => {
  return (dispatch) => {
    dispatch({ type: SET_ENTERED_GAMES_SCREEN, payload: entered });
  }
}

export const setCardFilter = (filterValue) => {
  return (dispatch) => {
    dispatch({ type: SET_CARD_FILTER, payload: filterValue })
  }
}

export const setActivities = (activities) => {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVITY, payload: activities })
  }
}

export const setWishLists = (wishlists) => {
  return (dispatch) => {
    dispatch({ type: SET_WISHLISTS, payload: wishlists })
  }
}

export const setCheers = (cheers) => {
  return (dispatch) => {
    dispatch({ type: SET_CHEERS, payload: cheers })
  }
}

export const setMyCards = (cards) => {
  return (dispatch) => {
    dispatch({ type: SET_MY_CARDS, payload: cards })
  }
}

export const setGoTopOfHome = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_GO_TOP_OF_HOME, payload: value })
  }
}

export const setSidebarCollapsed = (value) => {
  return (dispatch) => {
    console.log('setShowSidebarCollapsed', value)
    dispatch({ type: SET_SIDEBAR_COLLAPSED, payload: value })
  }
}

export const setHomeTabIndex = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_HOME_TAB_INDEX, payload: value })
  }
}

export const setChangedCard = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CHANGED_CARD, payload: value })
  }
}

export const setCurrentCard = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_CARD, payload: value })
  }
}

export const setChangedFanfeed = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CHANGED_FANFEED, payload: value })
  }
}

export const setCreatedFanfeed = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CREATED_FANFEED, payload: value })
  }
}

export const setCurrentWalletBalance = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_WALLET_BALANCE, payload: value })
  }
}

export const setExchangeRate = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EXCHANGE_RATE, payload: value })
  }
}

export const setFollowingUserIds = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_FOLLOWING_USER_IDS, payload: value })
  }
}

export const addFollowingUserId = (value) => {
  return (dispatch) => {
    dispatch({ type: ADD_FOLLOWING_USER_ID, payload: value })
  }
}

export const removeFollowingUserId = (value) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_FOLLOWING_USER_ID, payload: value })
  }
}

export const setEditCardAction = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_EDIT_CARD_ACTION, payload: value })
  }
}

export const setBuyCardAction = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_BUY_CARD_ACTION, payload: value })
  }
}

export const setAdvancedSearchFilterAction = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_ADVANCED_SEARCH_FILTER_ACTION, payload: value })
  }
}

export const setFanfeedFilters = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_FANFEED_FILTERS, payload: value })
  }
}

export const setFanfeedFilterAction = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_FANFEED_FILTER_ACTION, payload: value })
  }
}
