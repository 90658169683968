import * as Actions from '../../actions/main/index'

const initialState = {
  setting: {},
  teams: [],
  sports: [],
  profile: {},
  curLocation: {},
  cardFilter: {
    card_category_id: [],
    institution_type_id: [],
    year_created: [],
    team_id: [],
    team_level_id: [],
    card_type_id: [],
  },
  wishLists: [],
  cheers: [],
  activities: [],
  showBar: true,
  goTopOfHome: false,
  sideBarCollapsed: true,
  followingUserIds: [],

  currentCard: {},
  changedCard: {},
  changedFanfeed: {},
  createdFanfeed: {},
  exchangeRate: 0,
  currentWalletBalance: 0,
  editCardAction: false,
  buyCardAction: {},
  adsFilters: {
    talent_height_unit: 1,
    talent_weight_unit: 7,
  },
  fanfeedFilters: {
    selectedTalents: [],
    addresses: [],
  },
  fanfeedFiltersApplied: {},
}

const dataReducer = (state = initialState, action) => {
  // console.log('action', action)
  switch (action.type) {
    case Actions.SET_GAMEOPTIONS:
      return { ...state, game_options: action.payload }
    case Actions.SET_APPVERSION:
      // console.log('SET_APPVERSION', action.payload);
      return { ...state, version: action.payload }
    case Actions.SET_SETTING:
      // console.log('SET_SETTING', action.payload);
      return { ...state, setting: action.payload }
    case Actions.SAVE_SETTING:
      // APIKit.setSetting(state.setting);
      return state;
    case Actions.SET_TEAMS:
      // console.log('SET_TEAMS', action.payload);
      return { ...state, teams: action.payload }
    case Actions.SET_SPORTS:
      // console.log('SET_SPORTS', action.payload);
      return { ...state, sports: action.payload }
    case Actions.SET_PROFILE:
      // console.log('SET_PROFILE', action.payload);
      return { ...state, profile: action.payload }
    case Actions.SET_CURLOCATION:
      // console.log('SET_CURLOCATION', action.payload);
      return { ...state, curLocation: action.payload }
    case Actions.SET_SHOW_BAR:
      return { ...state, showBar: action.payload }
    case Actions.SET_CARD_FILTER:
      return {
        ...state,
        cardFilter: {
          ...state.cardFilter,
          [action.payload.type]: [...action.payload.filterItems]
        }
      }
    case Actions.SET_ACTIVITY:
      return { ...state, activities: action.payload }
    case Actions.SET_WISHLISTS:
      return { ...state, wishLists: action.payload }
    case Actions.SET_CHEERS:
      return { ...state, cheers: action.payload }
    case Actions.SET_MY_CARDS:
      return { ...state, myCards: action.payload }
    case Actions.SET_GO_TOP_OF_HOME:
      return { ...state, goTopOfHome: action.payload }
    case Actions.SET_SIDEBAR_COLLAPSED:
      return { ...state, sideBarCollapsed: action.payload }
    case Actions.SET_HOME_TAB_INDEX:
      return { ...state, homeTabIndex: action.payload }
    case Actions.SET_CHANGED_CARD:
      return { ...state, changedCard: action.payload }
    case Actions.SET_CURRENT_CARD:
      return { ...state, currentCard: action.payload }
    case Actions.SET_CHANGED_FANFEED:
      return { ...state, changedFanfeed: action.payload }
    case Actions.SET_CREATED_FANFEED:
      return { ...state, createdFanfeed: action.payload }
    case Actions.SET_EXCHANGE_RATE:
      return { ...state, exchangeRate: action.payload }
    case Actions.SET_CURRENT_WALLET_BALANCE:
      return { ...state, currentWalletBalance: action.payload }
    case Actions.SET_FOLLOWING_USER_IDS:
      return { ...state, followingUserIds: action.payload || [] }
    case Actions.ADD_FOLLOWING_USER_ID:
      return {
        ...state,
        followingUserIds: [
          parseInt(action.payload),
          ...state.followingUserIds
        ]
      }
    case Actions.REMOVE_FOLLOWING_USER_ID:
      return {
        ...state,
        followingUserIds: state.followingUserIds.filter(id => id != action.payload)
      }
    case Actions.SET_EDIT_CARD_ACTION:
      return { ...state, editCardAction: action.payload }
    case Actions.SET_BUY_CARD_ACTION:
      return { ...state, buyCardAction: action.payload }
    case Actions.SET_ADVANCED_SEARCH_FILTER_ACTION:
      return { ...state, adsFilters: action.payload }
    case Actions.SET_FANFEED_FILTERS:
      return { ...state, fanfeedFilters: action.payload }
    case Actions.SET_FANFEED_FILTER_ACTION:
      return { ...state, fanfeedFiltersApplied: action.payload }
    case Actions.CLEAR_ALL_DATA:
      return initialState
    default:
      return state;
  }
};

export default dataReducer;
