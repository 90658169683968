export const SET_AUTH_TOKEN = '[DATA] SET AUTH TOKEN';
export const SET_WALLET_USER_TOKEN = '[DATA] SET WALLET USER TOKEN';
export const SET_LOGGEDIN_USER = '[DATA] SET LOGGEDIN USER';
export const SET_LOOKUP_ITEMS = '[DATA] SET LOOKUP ITEMS';
export const SET_UNREAD_PUSH_COUNT = '[DATA] SET UNRAED PUSH COUNT';

export const setAuthToken = (token) => {
  return (dispatch) => {
    token ? localStorage.setItem('token', token) : localStorage.removeItem('token')
    dispatch({ type: SET_AUTH_TOKEN, payload: token });
  };
};

export const setWalletUserToken = (userToken) => {
  return (dispatch) => {
    // userToken ? localStorage.setItem('userToken', userToken) : localStorage.removeItem('userToken')
    dispatch({ type: SET_WALLET_USER_TOKEN, payload: userToken });
  };
};

export const setLoggedinUser = (user) => {
  return (dispatch) => {
    localStorage.setItem('user', JSON.stringify(user))
    dispatch({ type: SET_LOGGEDIN_USER, payload: user });
  };
};


export const setLookupItems = (look_up_items) => {
  return (dispatch) => {
    // console.log('actions SET_LOGGEDIN_USER');
    localStorage.setItem('look_up_items', JSON.stringify(look_up_items))
    dispatch({ type: SET_LOOKUP_ITEMS, payload: look_up_items });
  };
};

export const setUnreadPushCount = (count) => {
  return (dispatch) => {
    dispatch({ type: SET_UNREAD_PUSH_COUNT, payload: count });
  };
};
