import * as Actions from '../../actions/main/index';

const initialState = {
    token: '',
    userToken: '',
    user: {},
    institutionData: {},
    look_up_items: {},

    walletCountry: {
        "id": 197,
        "code": "ZA",
        "displayCurrency": "R",
        "exchangeCurrency": "ZAR",
        "name": "South Africa"
    },
    unreadPushCount: 0,
    walletInquiry: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_AUTH_TOKEN:
            return { ...state, token: action.payload };
        case Actions.SET_WALLET_USER_TOKEN:
            return { ...state, userToken: action.payload };
        case Actions.SET_LOGGEDIN_USER:
            return { ...state, user: action.payload };
        case Actions.CLEAR_ALL_DATA:
            return { ...state, user: {}, token: '' };;
        case Actions.SET_LOOKUP_ITEMS:
            return { ...state, look_up_items: action.payload };
        case Actions.SET_UNREAD_PUSH_COUNT:
            return { ...state, unreadPushCount: action.payload };
        default:
            return state;
    }
};

export default authReducer;
